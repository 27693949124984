var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list-v2', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV2.update($statusList, skuData);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $statusList = $('.js-inv-status-list-v2', $(this));

    if ($statusList.length < 1) {
      return null;
    }
    Drupal.behaviors.inventoryStatusV2.selectSku($statusList, skuBaseId);
  });

  $(document).on('inv_status_data_card:updated', '.js-product-card', function (e) {
    var $productCard = $(this);
    var $statusList = $('.js-inv-status-list-v2', $productCard);
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);
    var $addBtn = $('.js-add-to-cart', $productCard);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV2.update($statusList, skuData);
    }
    if ($addBtn.length > 0 && !!skuData) {
      if (skuData && !skuData.isShoppable) {
        $addBtn.addClass('button--disabled');
        $addBtn.data('disabled', true);
      } else {
        $addBtn.removeClass('button--disabled');
        $addBtn.data('disabled', false);
      }
    }
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list-v2', $(this));

    if ($statusList.length === 0) {
      return;
    }
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV2.update($statusList, skuData);
    }
  });

  $(document).on('mouseout', '.js-swatch-list-item-link', function () {
    var $swatchList = $(this).closest('.js-swatch-list');
    var skuBaseId = $swatchList.data('sku-base-id');
    var $productEl = prodcat.ui.getProductEl($swatchList);

    $productEl.trigger('product.skuDisplay', skuBaseId);
  });

  Drupal.behaviors.inventoryStatusV2 = {
    update: function ($statusList, skuData) {
      var skuBaseId = $statusList.data('sku-base-id');

      if (!skuData || !skuData.INVENTORY_STATUS) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }
      $('li', $statusList).hide();
      var $statusToShow = $('.js-inv-status-' + skuData.INVENTORY_STATUS, $statusList);

      if ($statusToShow) {
        $statusToShow.show();
        $statusList.trigger('inv_status_display:updated');
      }
      // Limited remaining should not appear when PRE-ORDER is active
      if (
        Drupal.settings.product_display.has_limited_remaining &&
        !!skuData.isLimitedRemaining &&
        skuData.MISC_FLAG != 15
      ) {
        var threshold_range = site.translations.product.threshold_range_few_left.split(',');

        if (
          skuData.highVelocityQty > threshold_range[1] &&
          skuData.highVelocityQty <= threshold_range[0]
        ) {
          $('.js-limited-remaining-highest', $statusList).show();
        } else if (skuData.highVelocityQty <= site.translations.product.threshold_limit_only_left) {
          $('.js-threshold-count').text(skuData.highVelocityQty);
          $('.js-limited-remaining-lowest', $statusList).show();
        }
      }
    },

    selectSku: function ($statusList, skuBaseId) {
      $statusList.data('sku-base-id', skuBaseId);
      var skuData = prodcat.data.getSku(skuBaseId);
      var $oneClick = $('.js-one-click-btn');

      $oneClick.trigger('one_click_overlay:oneclickValidation');
      Drupal.behaviors.inventoryStatusV2.update($statusList, skuData);
    }
  };
})(jQuery);
